import {CHANGE_CLOCK_ZONE, ADD_CLOCK, SET_CLOCK_ZONE, DELETE_CLOCK, SET_CLOCK_MAIN_ZONE, RESET_CLOCK, SET_CLOCK_SIZE, CHANGE_CLOCK_SIZE} from './types';
let timer = null;


export const setClockZone = (clockzone) => dispatch => {
    dispatch({
     type:SET_CLOCK_ZONE,
     payload:clockzone
    })
   }
   
export const resetClock = () => dispatch => {
    dispatch({
     type:RESET_CLOCK,
    })
   }

export const setClockSize = (clocksize) => dispatch => {
    
    dispatch({
     type:SET_CLOCK_SIZE,
     payload:clocksize
    })
   }

export const changeClockZone = (clockzone) => dispatch => {
    
    dispatch({type: CHANGE_CLOCK_ZONE,
        payload:clockzone});
   }

export const addClock = () => dispatch => {
       dispatch({
     type: ADD_CLOCK
    })
   }
export const changeClockSize = (clocksize) => dispatch => {
    
    dispatch({type: CHANGE_CLOCK_SIZE,
        payload:clocksize});
   }

export const deleteClock = (clockkey) => dispatch => {
    dispatch({
     type: DELETE_CLOCK,
     payload:clockkey
    })
   }

export const setClockMainZone = (clockzone) => dispatch => {
    
    dispatch({
     type:SET_CLOCK_MAIN_ZONE,
     payload:clockzone
    })
   }