import React, {Component} from "react";
//import Context from "./context";
// import Switch from '@material-ui/core/Switch';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Grid from '@material-ui/core/Grid';
// import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
//import MuiAlert from '@material-ui/lab/Alert';
import {connect} from 'react-redux';
import {alertAlarm,stopAlarm} from '../actions/alarmActions';
//import { TOGGLE_DARK_MODE } from "../actions/types";
import Sound from 'react-sound';
import  moment from 'moment-timezone';





class AlarmSound extends Component  {



render() {
  const isAlarmed = this.props.isAlarmed;
  const alarmtime = this.props.alarmtime;
  const clockzone = this.props.zone;
  const isTwelve = this.props.isTwelve;
return ( 
    <div>
      
      <Dialog
        open={isAlarmed}
        
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Alarm"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
           Your alarm for {isTwelve ? moment(alarmtime).tz(clockzone).format('hh:mm A'):moment(alarmtime).tz(clockzone).format('HH:mm')}
           
           <Sound
        url="Tech-house-synth-loop-122-bpm.mp3"
        playStatus={(isAlarmed) ? Sound.status.PLAYING:Sound.status.STOPPED }
        playFromPosition={300 /* in milliseconds */}
        loop
      />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.props.stopAlarm()} color="primary" autoFocus>
            Stop
          </Button>
        </DialogActions>
      </Dialog>
      </div>
  )
}
}
const mapStateToProps = state => ({
  isAlarmed: state.alarm.alert,
  alarmtime: state.alarm.time,
  zone: state.clock.maintimezone,
  isTwelve : state.isTwelve
});
const mapDispatchToProps = {
    stopAlarm,     
      alertAlarm
  };

export default connect(mapStateToProps, mapDispatchToProps)(AlarmSound);