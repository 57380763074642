import React from 'react';
//import { BrowserRouter as Router } from 'react-router-dom';
//import logo from './logo.svg';
//import './App.css';
//import Container from '@material-ui/core/Container';
//import DateTimePicker from './DateTimePicker';
//import Dashboard from './Components/Dashboard'
import MenuDrawer from './Components/MenuDrawer';
//import { Root, Header, Nav, Content, Footer } from "mui-layout";
//import lightBlue from '@material-ui/core/colors/lightBlue'
//import ClockGrid from './Components/ClockGrid';
//import Fab from '@material-ui/core/Fab';
import Paper from '@material-ui/core/Paper';
// import AutoGrid from './Components/AutoGrid';
// import AddIcon from '@material-ui/icons/Add';
// import Timerpage from './Components/Timerpage';
// import Schedulerpage from './Components/Schedulerpage';
// import Information from './Components/Information';
// import Settings from './Components/Settings';
// import Privacy from './Components/Privacy';
// import LinksPage from './Components/LinksPage';
// import CityRoutes from './Components/CityRoutes';
// import ShareRoutes from './Components/ShareRoutes';
//import Layout from './Components/Layout';
//import { Switch } from '@material-ui/core';
//import { makeStyles } from '@material-ui/core/styles';
import {useSelector} from 'react-redux';
import { darktheme, lighttheme } from './theme';
import { MuiThemeProvider,ThemeProvider } from '@material-ui/core/styles';
//import CookieConsent, { Cookies } from "react-cookie-consent";
//import Dialog from '@material-ui/core/Dialog';
//import MUICookieConsent from 'material-ui-cookie-consent';
//import CookieConsent, { Cookies } from "react-cookie-consent";
//import { ChevronLeft, MenuRounded, ChevronRight } from "@material-ui/icons";
//import Box from '@material-ui/core/Box';

import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import ReactGA from "react-ga";
import {Helmet} from "react-helmet";
//import AdSense from 'react-adsense';
//import { makeStyles } from '@material-ui/core/styles';
//import classes from '*.module.css';

//const useStyles = makeStyles((theme) => ({  root: {    display: 'flex',  },  header: {    padding: theme.spacing(3),  },}));

if (typeof window !== 'undefined') {
  ReactGA.initialize('UA-179477242-1', {
    debug: false,
    titleCase: false,
    gaOptions: {
      userId: 123,
      siteSpeedSampleRate: 50,
    },
  });
}

function App() {
  //const location = useLocation();
  //const classes = useStyles();
  const isDark = useSelector(state => state.isDark);
    return (
      <MuiThemeProvider theme={isDark ? darktheme:lighttheme}>
        <ThemeProvider theme={isDark ? darktheme:lighttheme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
        {/* <Router> */}
        <Helmet>
        <title>Date & Time .in [Clocks Scheduler Timer Alarm Stopwatch]</title>
        
        <meta name="description" content="Date and Time in different timezones. Create your custom clock setup with bookmark / share link. Schedule time between different timzones. Clocks Scheduler Timer Alarm Stopwatch. Timezone, City, UTC, Time, Date, Unix time, Ordinal date/ Julian date, Day of the week, Month."/>
        {/* <script data-ad-client="ca-pub-6485925408039858" async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script> */}
        </Helmet>
      <Paper>
    <div className="App">\
    
    {/* <CookieConsent
location="bottom"
buttonText="I understand"
cookieName="dateandtimeaccept"
debug= "true"


style={{ background: "#2B373B" }}
buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
expires={150}
>
<span>This website uses ccookies and similar technologies for the following purposes:{" "}</span>
<span style={{ fontSize: "10px" }}>Personalised ads and content, ad and content measurement, audience insights and product development</span>
<br></br><span style={{ fontSize: "10px" }}>You can find out more in our <a href="https://dateandtime.in/privacy">Privacy and Cookie policy</a> .</span>
</CookieConsent> */}

    <React.Suspense fallback={<em>Loading...</em>}>
            {/* <MUICookieConsent 
              cookieName="mySiteCookieConsent"
              componentType="Dialog" // default value is Snackbar
              message="We use cookies and similar technologies for the following purposes:
              Store and/or access information on a device,              
              Personalised ads and content, ad and content measurement, audience insights and product development              
              You can find out more in our privacy policy and cookie policy at https://dateandtime.in/privacy."
            /> */}
      
           <MenuDrawer/>
           
           {/* <Switch>
            
            <Route path="/" exact component={AutoGrid}/>
            <Route path="/scheduler" exact component={Schedulerpage}/>           
            <Route path="/timer" exact component={Timerpage}/>
            <Route path="/info" exact component={Information}/>
            <Route path="/settings" exact component={Settings}/>
            <Route path="/privacy" exact component={Privacy}/>
            <Route path="/zones" exact component={LinksPage}/>
            <Route path="/share" location={location} component={ShareRoutes}/>
            <Route path="/*" location={location} component={CityRoutes}/>
           
        </Switch> */}
        
    </React.Suspense>     
    </div>
    </Paper>
    {/* </Router> */}
    </MuiPickersUtilsProvider>
    </ThemeProvider>
    </MuiThemeProvider>
  );
}

export default App;
