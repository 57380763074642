import React from 'react';
import Divider from '@material-ui/core/Divider';
//import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
//import logo from './logo.svg';
//import './App.css';
//import Container from '@material-ui/core/Container';
//import DateTimePicker from './DateTimePicker';
//import Dashboard from './Components/Dashboard'

import Typography from '@material-ui/core/Typography';
import ReactGA from "react-ga";
import {Helmet} from "react-helmet";
//import { makeStyles } from '@material-ui/core/styles';

function Pageview(props) {
    if (typeof window !== 'undefined') {
      ReactGA.pageview(window.location.pathname + window.location.search);
      }
  return null;
  }
  

function Privacy() {
    return (
    
    <div>
        <Pageview key='privacy'/>
        <Helmet>
    <title>Date & Time .in</title>
    <link rel="canonical" href={`https://dateandtime.in/privacy`}></link>
    <meta name="description" content="Date and Time in different timezones. Create your custom clock setup with bookmark / share link. Schedule time between different timzones. Clocks Scheduler Timer Alarm Stopwatch. Timezone, City, UTC, Time, Date, Unix time, Ordinal date/ Julian date, Day of the week, Month."/>
    <meta name="description" content="Privacy and Cookie policy"/>
    </Helmet>
        <Typography variant='h3' color='textSecondary' component="h2">
        
        Privacy Policy
        
        </Typography>
        <Divider/>
        &nbsp;
        <Typography paragraph variant="body2">
       
<b>How do we use cookies on this board?</b>
<br></br>
We use files known as cookies on Date & Time to improve its performance and to enhance your user experience. By using Date & Time you agree that we can place these types of files on your device.
<br></br>
<br></br>
<b>What are cookies?</b>
<br></br>
Cookies are small text files that a website may put on your computer, or mobile device, when you first visit that site or one of its pages.

There are many functions that a cookie can serve. For example, a cookie will help the website, or another website, to recognise your device the next time you visit it. Date & Time uses the term "cookies" in this policy to refer to all files that collect information in this way.

Certain cookies contain personal information – for example, if you click on "remember me" when logging on, a cookie will store your username. Most cookies will not collect information that identifies you, but will instead collect more general information such as how users arrive at and use Date & Time, or a user’s general location.
<br></br>
<br></br>
<b>What sort of cookies does Date & Time use?</b>
<br></br>
Cookies can perform several different functions:
<br></br>
<b>1. Necessary Cookies</b>
<br></br>
Some cookies are essential for the operation of Date & Time. These cookies enable services you have specifically asked for.
<br></br>
<br></br>
<b>2. Performance Cookies</b>
<br></br>
These cookies may collect anonymous information on the pages visited. For example, we might use performance cookies to keep track of which pages are most popular, which method of linking between pages is most effective and to determine why some pages are receiving error messages.
<br></br>
<br></br>
<b>3. Functionality Cookies</b>
<br></br>
These cookies remember choices you make to improve your experience.

Date & Time may also allow third parties to serve cookies that fall into any of the categories above.
 For example, like many sites, we may use Google Analytics to help us monitor our website traffic.
 For more information on the privacy practices of Google, please visit the <a href="http://www.google.com/privacy_cookies.html" target="_blank" rel="noopener noreferrer">Google Privacy & Terms</a>
<br></br>
<br></br>
<b>Can a site user block cookies?</b><br></br>
To find out how to manage which cookies you allow, see your browser’s help section or your mobile device manual - or you can visit one of the sites below, which have detailed information on how to manage, control or delete cookies.<br></br>
<a href="http://www.aboutcookies.org" target="_blank" rel="noopener noreferrer">www.aboutcookies.org</a><br></br>
<a href="http://www.allaboutcookies.org" target="_blank" rel="noopener noreferrer">www.allaboutcookies.org</a><br></br>
Please remember that if you do choose to disable cookies, you may find that certain sections of DateandTime.in do not work properly.
<br></br>
<br></br><b>Cookies on DateandTime.in from social networking sites:</b><br></br>
DateandTime.in may have links to social networking websites (e.g. Facebook, Twitter or YouTube). 
These websites may also place cookies on your device and DateandTime.in does not control how they use their cookies,
 therefore DateandTime.in suggests you check their website(s) to see how they are using cookies.
 <br></br>
 <br></br>
 
For any questions contact us by email : <a href="mailto:email@dateandtime.in" target="_blank" rel="noopener noreferrer" class="postlink">email@dateandtime.in</a>
    </Typography>
    </div>
  );
}

export default Privacy;
