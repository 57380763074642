import {CHANGE_SCHEDULER_ZONE, ADD_SCHEDULER, SET_SCHEDULER_ZONE, DELETE_SCHEDULER, SET_SCHEDULER_MAIN_ZONE, RESET_SCHEDULER, SET_SCHEDULER_START, SET_SCHEDULER_END} from '../actions/types';
import  moment from 'moment-timezone';


var m = moment().utcOffset(0);
m.set({hour:0,minute:0,seconds:0});
m.toISOString();

var s= moment();
s.set({hour:0,minute:0,seconds:0});
s.toISOString();

let schedulers = [];
schedulers[0] = {timezone: moment.tz.guess(), item:{city: (" "), number:"0"}};
let zonetemp = moment.tz.guess()

let initialState = {maintimezone: moment.tz.guess(),
  keys:0,
  startdateandtime:moment(s.format()).tz(zonetemp),
  enddateandtime:moment(s.format()).tz(zonetemp),
  schedulerarray:schedulers
}


const schedulerReducer = (state = initialState, action) => {
    switch (action.type) {
      case ADD_SCHEDULER:
        return {
          ...state,
          keys: state.keys + 1  
        };

      case DELETE_SCHEDULER:
        return{
          ...state,
          schedulerarray: [...state.schedulerarray.slice(0, action.payload), ...state.schedulerarray.slice(action.payload + 1)],         
        };
    
      case RESET_SCHEDULER:{
        return initialState;
      }
      case CHANGE_SCHEDULER_ZONE:
        return {
          ...state,
          schedulerarray: state.schedulerarray.slice(0, action.payload.schedulerkey).concat({timezone: action.payload.timezone, item:action.payload.item}).concat(state.schedulerarray.slice(action.payload.schedulerkey + 1)),
        };
      case SET_SCHEDULER_ZONE:
         
          return {
            ...state,
            schedulerarray: state.schedulerarray.concat({timezone: action.payload.timezone, item:action.payload.item}),
          };
       
      case SET_SCHEDULER_MAIN_ZONE:
            return {
              ...state,
              maintimezone: action.payload.timezone,
              schedulerarray: state.schedulerarray.slice(0, 0).concat({timezone: action.payload.timezone, item:action.payload.item}).concat(state.schedulerarray.slice(0 + 1)),
        };
      case SET_SCHEDULER_START:
          return {
            ...state,
            startdateandtime: action.payload,
          };
      case SET_SCHEDULER_END:
        return {
          ...state,
          enddateandtime: action.payload,
        };
      
      default:
        return state;
    }
  }

export default schedulerReducer;