import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import AlarmComponent from './AlarmComponent';



export default function Alarm(props) {
  const size = props.size;
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    grids:
    {
      height: "100%",
      padding: theme.spacing(2),
     },
  }));


const classes = useStyles();
  
  return (

<Grid item xs={size} className={classes.grids}>
<Paper elevation={6}  className={classes.paper}>
            <AlarmComponent/>
            </Paper> 
          </Grid>
    )
}
