import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import SvgIcon from '@material-ui/core/SvgIcon';

const useStyles = makeStyles((theme) => ({
    root: {
      '& > svg': {
        margin: theme.spacing(0),
      },
      '&:hover':{
          color: theme.palette.primary.main,
      },
    },
  }));

export default function BackIconSvg(props) {
    const classes = useStyles();
  return (
    
    <SvgIcon className={classes.root} {...props}>
      <path d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z" />
    </SvgIcon>
    
  );
}
