import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import SvgIcon from '@material-ui/core/SvgIcon';

const useStyles = makeStyles((theme) => ({
  root: {
    
    '& > svg': {
      margin: theme.spacing(0),
    },
    '&:hover':{
        color: theme.palette.primary.main,
    },
  },
}));

export default function CopyIconSvg(props) {
    const classes = useStyles();
  return (
    
    <SvgIcon className={classes.root} {...props}>
      <path d="M19,21H8V7H19M19,5H8A2,2 0 0,0 6,7V21A2,2 0 0,0 8,23H19A2,2 0 0,0 21,21V7A2,2 0 0,0 19,5M16,1H4A2,2 0 0,0 2,3V17H4V3H16V1Z" />
    </SvgIcon>
    
  );
}
