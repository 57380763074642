import React, {Component} from "react";
//import Context from "./context";
//import Switch from '@material-ui/core/Switch';
//import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Grid from '@material-ui/core/Grid';
// import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {connect} from 'react-redux';
import {alertTimer,stopTimer} from '../actions/timerActions';
import Sound from 'react-sound';
//import { TOGGLE_DARK_MODE } from "../actions/types";

class TimerSound extends Component  {

render() {
  const isTimed = this.props.isTimed;
return ( 
    <div>

      <Dialog
        open={isTimed}
        
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Timer"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
           Timer countdown complete
           <Sound
        url="Saw-blip-synth-loop-140-bpm.mp3"
        playStatus={(isTimed) ? Sound.status.PLAYING:Sound.status.STOPPED }
        playFromPosition={300 /* in milliseconds */}
        loop
      />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.props.stopTimer()} color="primary" autoFocus>
            Done
          </Button>
        </DialogActions>
      </Dialog>
      </div>
  )
}
}
const mapStateToProps = state => ({
  isTimed: state.timer.alert
});
const mapDispatchToProps = {
    stopTimer,     
      alertTimer
  };

export default connect(mapStateToProps, mapDispatchToProps)(TimerSound);