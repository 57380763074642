import React, {Component} from "react";
//import Context from "./context";
//import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
//import { TOGGLE_DARK_MODE } from "../actions/types";

class SocialpageLinks extends Component  {

render() {
  const isTwelve = this.props.isTwelve;
return ( 
  <Typography component="label" color="textSecondary">
    
  <Grid container alignItems="center" spacing={0}>
    <Grid item> &nbsp;&nbsp;&nbsp;</Grid>
    <Grid item>
    <Tooltip title="Follow our Twitter feed">
      <Button size="small">
    <Link  style={{ textDecoration: 'none' }}	href="https://twitter.com/dateandtimein">
            <TwitterIcon color="disabled"/>
            </Link>
            </Button>
            </Tooltip>
            <Tooltip title="Like our Facebook page">
            <Button size="small">
            <Link  style={{ textDecoration: 'none' }}	href="https://facebook.com/dateandtimein">
            <FacebookIcon color="disabled"/>
            </Link>
            </Button>
            </Tooltip>
      </Grid>
          
        </Grid>
      </Typography>
  )
}
}

export default SocialpageLinks;