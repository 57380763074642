import {START_ALARM, TOGGLE_ALARM, STOP_ALARM, RESET_ALARM, SET_ALARM, ALERT_ALARM, TICK_ALARM} from './types';
import  moment from 'moment-timezone';

var m = moment().utcOffset(0);
m.set({hour:0,minute:0,seconds:0});
m.toISOString();


let timer = null;

export const startAlarm = () => dispatch => {
    clearInterval(timer);
    timer = setInterval(() => dispatch(tickAlarm()), 1000);
    dispatch({type: START_ALARM});
    
   }

export const stopAlarm = () => dispatch => {
    clearInterval(timer);
    dispatch({
     type: STOP_ALARM
    })
   }

export const toggleAlarm = () => (dispatch,getState) => {
    if (getState().alarm.running)
    {
    clearInterval(timer);
    dispatch({
     type: TOGGLE_ALARM
    })
   } else {
    clearInterval(timer);
    timer = setInterval(() => dispatch(tickAlarm()), 1000);
    dispatch({type: START_ALARM});
   }
}
export const tickAlarm = () => (dispatch, getState) => {
    if(moment(getState().alarm.timer).format('HH:mm') >= moment(getState().alarm.time).format('HH:mm')){
        clearInterval(timer);
        dispatch({
            type:ALERT_ALARM 
           }) 
    } else {
        
    dispatch({
     type:TICK_ALARM 
    })
    }
   }



export const resetAlarm = () => dispatch => {
    clearInterval(timer);
    dispatch({
     type: RESET_ALARM
    })
   }
   


export const setAlarm = (alarmset) => dispatch => {
    dispatch({
     type:SET_ALARM,
     payload:alarmset
    })
   }

   export const alertAlarm = () => (dispatch,getState) => {
    clearInterval(timer);
     dispatch({
     type:ALERT_ALARM,
    })
   }