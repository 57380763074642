import React from 'react';
import {Link} from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {Helmet} from "react-helmet";
import ReactGA from "react-ga";
//import AutoGrid from './AutoGrid';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import  moment from 'moment-timezone';
import BackIconSvg from '../SvgIcons/BackIconSvg';
import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(0.5),
      },
    },
    chips: {
        '&:hover':{
            backgroundColor: theme.palette.primary.main,
        },
      },
  }));

  function Pageview(props) {
    if ((typeof window !== 'undefined')&&(process.env.NODE_ENV == 'production')) {
      ReactGA.pageview(window.location.pathname + window.location.search);
      }
  return null;
  }

export default function LinksPage() {
    const timezones = moment.tz.names();
    //console.log(timezones);
    const classes = useStyles();
    
    function Zoneslist() {

        return(
          
          timezones.map((zone) =>
            {
            
                return <Link style={{ textDecoration: 'none' }} to={`/`+zone}><Chip className={classes.chips} color="inherit" clickable label={zone}/></Link>;
              
            }
      
          )
      
      
        )
      }

    return (
        <div  className={classes.root}>
            <Helmet>
               <title>Date and Time .in DateandTime.in</title>
              <link rel="canonical" href={`https://dateandtime.in/`}></link>
              <meta name="description" content="Date and Time in different timezones. Create your custom clock setup with bookmark / share link. Schedule time between different timzones. Clocks Scheduler Timer Alarm Stopwatch. Timezone, City, UTC, Time, Date, Unix time, Ordinal date/ Julian date, Day of the week, Month."/>
            </Helmet>
            <Pageview key="zones"/>
            <Typography variant="h3" color="textSecondary">Change main Timezone for Clock and Scheduler</Typography>
    <Link to="/" style={{ textDecoration: 'none' }}>
        <Chip label="Back to clock"
              avatar={<Avatar><BackIconSvg color="primary" style={{ fontSize: 20 }}/></Avatar>}
              clickable
              color="primary"
              
        />
        {/* <Typography variant="button" color="primary"><BackIconSvg color="primary" style={{ fontSize: 20 }}/>Back to clock</Typography> */}
        </Link>
            <Zoneslist/>
            
        </div>

        )
    }