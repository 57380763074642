import React from 'react';
import Divider from '@material-ui/core/Divider';
import {Link} from 'react-router-dom';
//import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
//import logo from './logo.svg';
//import './App.css';
//import Container from '@material-ui/core/Container';
//import DateTimePicker from './DateTimePicker';
//import Dashboard from './Components/Dashboard'

import Typography from '@material-ui/core/Typography';
import ReactGA from "react-ga";
import {Helmet} from "react-helmet";
//import { makeStyles } from '@material-ui/core/styles';

function Pageview(props) {
  if ((typeof window !== 'undefined')&&(process.env.NODE_ENV == 'production')) {
    ReactGA.pageview(window.location.pathname + window.location.search);
    }
return null;
}



function Information() {
    return (
    
    <div>
        <Pageview key='information'/>
        <Helmet>
    <title>Date & Time .in</title>
    <link rel="canonical" href={`https://dateandtime.in/info`}></link>
    <meta name="description" content="Date and Time in different timezones. Create your custom clock setup with bookmark / share link. Schedule time between different timzones. Clocks Scheduler Timer Alarm Stopwatch. Timezone, City, UTC, Time, Date, Unix time, Ordinal date/ Julian date, Day of the week, Month."/>
    <meta name="description" content=" About the site & Contact Us Information"/>
    </Helmet>
        <Typography variant='h3' color='textSecondary' component="h2">
        
        Information Page
        
        </Typography>
        <Divider/>
        &nbsp;
        <Typography paragraph variant="body2">
        <Typography paragraph variant="h4"  color="textSecondary">
        Contact Us
        </Typography>
        Email : <a href="mailto:email@dateandtime.in" target="_blank" rel="noopener noreferrer" class="postlink">email@dateandtime.in</a><br></br>
        <br></br><br></br><br></br>


<Typography paragraph variant="h4"  color="textSecondary">
About
</Typography>
Site created using <a href="https://material-ui.com/" target="_blank" rel="noopener noreferrer" class="postlink">Material-UI</a> and <a href="https://momentjs.com/" target="_blank" rel="noopener noreferrer" class="postlink">Moment.js</a> <br></br>
Saw Blip Synth Loop 140 bpm and 
Tech House Synth Loop by <a href="https://www.facebook.com/AlexanderBluMusic/" target="_blank" rel="noopener noreferrer">Alexander Blu</a> available by <a href="https://creativecommons.org/licenses/by/4.0/legalcode" target="_blank" rel="noopener noreferrer">Creative Commons Attribution 4.0 International License</a><br></br>
<br></br>

View our Privacy & Cookie Policy <Link  style={{ textDecoration: 'none' }} to="/privacy">here</Link>.<br></br>

<br></br>
<Typography paragraph variant="h4"  color="textSecondary">
      Links on the site
      </Typography>
<a href="./">Main page/Clocks</a><br></br>
<a href="./scheduler">Scheduler</a><br></br>
<a href="./timer">Timer, Stopwatch and Alarm</a>
 
        </Typography>

    </div>
  );
}

export default Information;
