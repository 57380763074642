import React from 'react';
import Typography from '@material-ui/core/Typography';
import {Helmet} from "react-helmet";

export default function AdsInline(props) {
    switch(props.page)
    {
    case "clock":
        return(
            <React.Fragment key={props.adskey}><input type="hidden" name="IL_IN_TAG" value="1"/></React.Fragment>
            // <React.Fragment key={props.adskey}><p></p></React.Fragment>
            )
    case "scheduler":
         return(
            <React.Fragment key={props.adskey}><div id="amzn-assoc-ad-81f829c5-9fc1-4010-9e0b-e4d8487ee4a8"></div><Helmet><script async src="//z-na.amazon-adsystem.com/widgets/onejs?MarketPlace=US&adInstanceId=81f829c5-9fc1-4010-9e0b-e4d8487ee4a8"></script></Helmet></React.Fragment>
            )
    case "timer":
        return(
            <React.Fragment key={props.adskey}><Helmet><script type="text/javascript" language="javascript"> var aax_size='300x600'; var aax_pubname = 'dateandtimein-21'; var aax_src='302';</script><script type="text/javascript" language="javascript" src="http://c.amazon-adsystem.com/aax2/assoc.js"></script></Helmet></React.Fragment>
        
            )
    default :
        return(
            <Typography key={props.adskey} gutterBottom align="center" variant="caption" component="h4"> &nbsp;<p></p>&nbsp;<p></p>&nbsp;<p></p>&nbsp;<p></p></Typography>
            )
    }
}