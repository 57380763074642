import {TOGGLE_TWELVE_MODE} from '../actions/types';


const is12Reducer = (state = false, action) => {
    switch (action.type) {
      case TOGGLE_TWELVE_MODE:{
        return !state;
      }
      default:
        return state;
    }
  }

export default is12Reducer;