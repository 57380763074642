import {START_ALARM, TOGGLE_ALARM, STOP_ALARM, RESET_ALARM, SET_ALARM, ALERT_ALARM, TICK_ALARM} from '../actions/types';
import moment from 'moment-timezone';
//import {useSelector} from 'react-redux';

var m = moment().utcOffset(0);
m.set({hour:0,minute:0,seconds:0});
m.toISOString();

let initialState = {
  time: moment(m.format()).utcOffset(0),
  timer:moment(m.format()).utcOffset(0),
  running: false,
  stamp: 0,
  alert:false,
  mainzone: moment.tz.guess()
}


const alarmReducer = (state = initialState, action) => {
    switch (action.type) {
      case START_ALARM:
        return {
          ...state,
          timer:state.timer,
          running: true,
          stamp: moment().format()
        };
      case STOP_ALARM:
        return{
          ...state,
          running: false,
          alert: false
        };
    
      case RESET_ALARM:{
        return initialState;
      }
      case TOGGLE_ALARM:
        return {
          ...state,
          running: !state.running
        };
      case SET_ALARM:
          return {
            ...state,
            time: moment(action.payload).tz(state.mainzone)
          };
      case TICK_ALARM:
        return {
          ...state,
          timer: moment().format()
        };
      case ALERT_ALARM:
            return{
              ...state,
              alert: true
        };
        
      
      default:
        return state;
    }
  }

export default alarmReducer;