import {START_STOPWATCH, TICK_STOPWATCH, GET_STOPWATCH, STOP_STOPWATCH, RESET_STOPWATCH} from '../actions/types';


let initialState = {
  time: 0,
  running: false,
  stamp: 0,
  paused: 0
}


const stopwatchReducer = (state = initialState, action) => {
    switch (action.type) {
      case START_STOPWATCH:
        return {
          ...state,
          running: true,
          paused: state.time,
          stamp: Math.floor(Date.now())
        }
      case STOP_STOPWATCH:
        return{
          ...state,
          running: false,
          paused: state.time
        }
    
      case RESET_STOPWATCH:{
        return initialState;
      }
      case TICK_STOPWATCH:
        return {
          ...state,
          time: state.paused + (Math.floor(Date.now()) - state.stamp)
        }
      
      case GET_STOPWATCH:{
        return state;
      }

      default:
        return state;
    }
  }

export default stopwatchReducer;