import React from 'react';
import { Route } from 'react-router-dom';
import AutoGrid from './AutoGrid';
//import MenuDrawer from './MenuDrawer';
import cityroutes from './Cityandzoneroutes.json';
import { setClockMainZone} from '../actions/clockActions';
import { setSchedulerMainZone} from '../actions/schedulerActions';
import mainstore from '../store';
import {Helmet} from "react-helmet";
import  moment from 'moment-timezone';
import Alert from '@material-ui/lab/Alert';
import { AlertTitle } from '@material-ui/lab';
//import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
//import { SET_SCHEDULER_ZONE } from 'actions/types';
//import { useHistory } from "react-router-dom";

//const cityroutes  = React.lazy(() => import('./Cityandzoneroutes.json'));

export default function CityRoutes(props) {
  
  moment.tz.names().map((zone)=>
    cityroutes.push({"TimeZoneName":zone ,"TimeZone":zone ,"Link":zone})
  );
  //let history = useHistory();
  
  const location = props.location.pathname.replace("/","");
  //console.log("city");
  const city = (cityroutes.find(item => item.Link.toLowerCase() == location.toLowerCase()))||null;
  //console.log(city+"city");
  const [open, setOpen] = React.useState(true);

    return (
      // <Router>
        <div>
            <Helmet>
               { city ? <title>Date & Time .in {city.TimeZoneName}</title> : <title>DateandTime.in</title>}
              <link rel="canonical" href={`https://dateandtime.in/`+location}></link>
              { city ? <meta name="description" content="Date and Time in different timezones. Create your custom clock setup with bookmark / share link. Schedule time between different timzones. Clocks Scheduler Timer Alarm Stopwatch. Timezone, City, UTC, Time, Date, Unix time, Ordinal date/ Julian date, Day of the week, Month."/> : null}
            </Helmet>
            <React.Fragment>
            { city ? mainstore.dispatch(setClockMainZone({size:12, timezone:city.TimeZone, item:{city: (" "), number:"0"}})) : null}
            { city ? mainstore.dispatch(setSchedulerMainZone({timezone:city.TimeZone, item:{city: (" "), number:"0"}})) : null}
            { city ? moment.tz.setDefault(city.TimeZone) : null}
            { city ? null :<Collapse in={open}><Alert severity="success" color="error" onClose={() => {setOpen(false);}}> <AlertTitle>404:Link "https://dateandtime.in/{location}" not found !</AlertTitle>You have been redirected to the Home Page</Alert></Collapse>}
            
            {/* { city ? history.push('/'): history.push('/')} */}
              { city ? <Route path="*" key={location} component={AutoGrid}/>: <Route path="/" key="share"  component={AutoGrid} status={404}/>}
              
            </React.Fragment> 
            
        </div> 
      // </Router>
    )
  }
