import {START_STOPWATCH, TICK_STOPWATCH, STOP_STOPWATCH, RESET_STOPWATCH, GET_STOPWATCH} from './types';
let timer = null;

export const startStopwatch = () => dispatch => {
    clearInterval(timer);
    timer = setInterval(() => dispatch(tickStopwatch()), 10);
    dispatch({type: START_STOPWATCH});
    
   }

export const stopStopwatch = () => dispatch => {
    clearInterval(timer);
    dispatch({
     type: STOP_STOPWATCH
    })
   }


export const resetStopwatch = () => dispatch => {
    clearInterval(timer);
    dispatch({
     type: RESET_STOPWATCH
    })
   }

export const tickStopwatch = () => dispatch => {
    dispatch({
     type:TICK_STOPWATCH 
    })
   }


export const getStopwatch = () => dispatch => {
    dispatch({
     type:GET_STOPWATCH
    })
   }