import { createMuiTheme } from '@material-ui/core/styles';
import lightBlue from '@material-ui/core/colors/lightBlue';
import red from '@material-ui/core/colors/red';
//import Digital7Mono from './Fonts/digital-7.mono.ttf';
//import RobotoMono from './Fonts/Roboto_Mono/RobotoMono-VariableFont_wght.ttf';
//import B612MonoRegular from './Fonts/B612_Mono/B612Mono-Regular.ttf';
import "fontsource-b612-mono";
import "fontsource-roboto-mono";
import "fontsource-share-tech-mono";
import "fontsource-cutive-mono";
import "fontsource-courier-prime";
//import { green } from '@material-ui/core/colors';"Roboto Mono""B612 Mono""Share Tech Mono" "Cutive Mono""Courier Prime"
//import "fontsource-share-tech-mono/500.css" // All styles included.
//import "fontsource-share-tech-mono/900-normal.css" // Select normal


export const lighttheme = createMuiTheme({
  palette: {
    type: 'light',
    background: {
      paper: "#FFFFFF"
    },
    primary: {
      main: lightBlue[500],
    },
    secondary: {
      main: red[500],
    },
  },
  typography: {
    fontFamily: "Share Tech Mono",
  },
});
export const darktheme = createMuiTheme({
  palette: {
    type:'dark',
    background: {
      paper: "#333333"
    },
    primary: {
      main: lightBlue[500],
    },
    secondary: {
      main: red[500],
    },
  },
  typography: {
    fontFamily: "Share Tech Mono",
  },
});

