import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import SvgIcon from '@material-ui/core/SvgIcon';

const useStyles = makeStyles((theme) => ({
    root: {
      '& > svg': {
        margin: theme.spacing(0),
      },
      '&:hover':{
          color: theme.palette.primary.main,
      },
    },
  }));

export default function CloseIconSvg(props) {
    const classes = useStyles();
  return (
    
    <SvgIcon className={classes.root} {...props}>
      <path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
    </SvgIcon>
    
  );
}
