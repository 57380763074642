import {CHANGE_SCHEDULER_ZONE, ADD_SCHEDULER, SET_SCHEDULER_ZONE, DELETE_SCHEDULER, SET_SCHEDULER_MAIN_ZONE, RESET_SCHEDULER, SET_SCHEDULER_START, SET_SCHEDULER_END} from './types';


export const setSchedulerZone = (schedulerzone) => dispatch => {
    
    dispatch({
     type:SET_SCHEDULER_ZONE,
     payload:schedulerzone
    })
   }

export const setSchedulerStart = (startdateandtime) => dispatch => {
    
    dispatch({
     type:SET_SCHEDULER_START,
     payload:startdateandtime
    })
   }

export const setSchedulerEnd = (enddateandtime) => dispatch => {
    
    dispatch({
     type:SET_SCHEDULER_END,
     payload:enddateandtime
    })
   }
export const resetScheduler = () => dispatch => {
    
    dispatch({type: RESET_SCHEDULER});
    
   }

export const changeSchedulerZone = (schedulerzone) => dispatch => {
    
    dispatch({type: CHANGE_SCHEDULER_ZONE,
              payload:schedulerzone
    });
    
   }

export const addScheduler = () => dispatch => {
       dispatch({
     type: ADD_SCHEDULER
    })
   }


export const deleteScheduler = (schedulerkey) => dispatch => {
    
    dispatch({
     type: DELETE_SCHEDULER,
     payload: schedulerkey
    })
   }

export const setSchedulerMainZone = (clockzone) => dispatch => {
    dispatch({
     type:SET_SCHEDULER_MAIN_ZONE,
     payload:clockzone
    })
   }