import {CHANGE_CLOCK_ZONE, ADD_CLOCK, SET_CLOCK_ZONE, DELETE_CLOCK, SET_CLOCK_MAIN_ZONE, RESET_CLOCK, SET_CLOCK_SIZE, CHANGE_CLOCK_SIZE} from '../actions/types';
import  moment from 'moment-timezone';


var m = moment().utcOffset(0);
m.set({hour:0,minute:0,seconds:0});
m.toISOString();

let clocks = [];
clocks[0] = {size:12, timezone: moment.tz.guess(),  item:{city: (" "), number:"0"}};


let initialState = {maintimezone: moment.tz.guess(),keys:0,setsize:4, clockarray:clocks}


const clockReducer = (state = initialState, action) => {
    switch (action.type) {
      case ADD_CLOCK:
        return {
          ...state,
          keys: state.keys + 1  
        };

      case DELETE_CLOCK:
        return{
          ...state,
          clockarray: [...state.clockarray.slice(0, action.payload), ...state.clockarray.slice(action.payload + 1)],
          keys: state.keys -1
          
        };
    
      case RESET_CLOCK:{
        return initialState;
      }
      case SET_CLOCK_SIZE:
        return  {
          ...state,
          setsize: action.payload,  
        };
      
      case CHANGE_CLOCK_ZONE:
        return {
          ...state,
          clockarray: state.clockarray.slice(0, action.payload.clockkey).concat({size:action.payload.size, timezone: action.payload.timezone, item:action.payload.item}).concat(state.clockarray.slice(action.payload.clockkey + 1)),
        };
      case CHANGE_CLOCK_SIZE:
          return {
            ...state,
            clockarray: state.clockarray.slice(0, action.payload.clockkey).concat({size:action.payload.size, timezone: action.payload.timezone, item:action.payload.item}).concat(state.clockarray.slice(action.payload.clockkey + 1)),
          };
      case SET_CLOCK_ZONE:
          return {
            ...state,
            clockarray: state.clockarray.concat({size:action.payload.size, timezone: action.payload.timezone, item:action.payload.item}),
          };
      case SET_CLOCK_MAIN_ZONE:
            return {
              ...state,
              maintimezone: action.payload.timezone,
              clockarray: state.clockarray.slice(0, 0).concat({size:12, timezone: action.payload.timezone, item:action.payload.item}).concat(state.clockarray.slice(0 + 1)),
        };
      
      default:
        return state;
    }
  }

export default clockReducer;