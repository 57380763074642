import React,{useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {useSelector} from 'react-redux';
//import Card from '@material-ui/core/Card';
//import CardActions from '@material-ui/core/CardActions';
//import CardContent from '@material-ui/core/CardContent';
//import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import Hidden from '@material-ui/core/Hidden';
// import Modal from '@material-ui/core/Modal';
// import EditIcon from '@material-ui/icons/Edit';
// import CloseIcon from '@material-ui/icons/Close';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
// import SvgIcon from '@material-ui/core/SvgIcon';
import EditIconSvg from '../SvgIcons/EditIconSvg';
import CloseIconSvg from '../SvgIcons/CloseIconSvg';
// import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
// import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
// import { MuiPickersUtilsProvider,  KeyboardDatePicker,  KeyboardTimePicker,  KeyboardDateTimePicker} from '@material-ui/pickers';
//import Paper from '@material-ui/core/Paper';
//import Grid from '@material-ui/core/Grid';
// import { format } from 'moment';
// import {CopyToClipboard} from 'react-copy-to-clipboard';
import  moment from 'moment-timezone';
// import timeZone from 'moment-timezone';
// import MomentUtils from "@date-io/moment"
import mainstore from '../store';
import {deleteScheduler} from '../actions/schedulerActions';
import SchedulerzoneComponent from './SchedulerzoneComponent';
//import {zonedTimeToUtc, utcToZonedTime, format} from 'date-fns-tz';
//import { eoLocale} from 'date-fns/locale/eo';
//import enGB from 'date-fns/locale/en-GB';


const useStyles = makeStyles((theme) => ({
  root: {
    '& > svg': {
      margin: theme.spacing(2),
    },
    flexGrow: 1,
  },
  paper: {
    
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  time: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  small: {
    padding: theme.spacing(0),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  grids:
  {
    height: "100%",
    padding: theme.spacing(0),
   },
   clipbutton:
   {
     height: "100%",
     padding: theme.spacing(2),
     color: theme.palette.text.secondary,
    },
}));

function ChangeRemove(props) {
  const schedulerkey= props.schedulerkey;
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  
  const handleClose = () => {
    setOpen(false);
  };
  if (schedulerkey === 0) {
    return (
    <Box display="flex" alignContent="flex-start" alignItems="flex-start" justifyContent="flex-end">
                  <Tooltip title="Edit Size/Timezone"  TransitionComponent={Zoom} placement="top">
             <IconButton onClick={handleOpen} color="default" aria-label="Change Scheduler" component="span" size="small">
           
            
        <EditIconSvg style={{ fontSize: 15 }} />
   
          </IconButton>
          </Tooltip>
          <Dialog
    open={open}
    onClose={handleClose}
    aria-labelledby="simple-modal-title"
    aria-describedby="simple-modal-description"
  >
  <SchedulerzoneComponent  actiontype="change" schedulerkey={schedulerkey}/>
  </Dialog>
          </Box>
    
  )
  } else {
    return (
      <Box display="flex" alignContent="flex-start" alignItems="flex-start" justifyContent="flex-end">
                    <Tooltip title="Edit Size/Timezone"  TransitionComponent={Zoom} placement="top">
               <IconButton onClick={handleOpen} color="default" aria-label="Change Scheduler" component="span" size="small">
             
              
          <EditIconSvg style={{ fontSize: 15 }} />
     
            </IconButton>
            </Tooltip>
            <Tooltip title="Remove Scheduler"  TransitionComponent={Zoom} placement="top">
            <IconButton onClick={() => { mainstore.dispatch(deleteScheduler(schedulerkey))}} color="default" aria-label="Remove Scheduler" component="span" size="small">
              <CloseIconSvg style={{ fontSize: 15 }}/>
            </IconButton>
            </Tooltip>
            <Dialog
    open={open}
    onClose={handleClose}
    aria-labelledby="simple-modal-title"
    aria-describedby="simple-modal-description"
  >
  <SchedulerzoneComponent actiontype="change" schedulerkey={schedulerkey}/>
  </Dialog>
            </Box>
      
    )}
  };


export default function Scheduler(props) {
  const classes = useStyles();
  const schedulerkey = props.schedulerkey;
  const size = props.size;
  const schedulerzone= props.schedulerzone;
  //const scheduleritem= props.scheduleritem;
  const [time, setTime] = useState(Date.now());
  //const currentzone = moment.tz.guess();
  //const utcTime = zonedTimeToUtc(time, currentzone);
  //const zonedTime = utcToZonedTime(time, currentzone);
  var m = moment().utcOffset(0);
  m.set({hour:0,minute:0,seconds:0});
  m.toISOString();

  //const momenttime = moment(time);
  const isTwelve = useSelector(state => state.isTwelve);
  const schedStartDate =useSelector(state => state.scheduler.startdateandtime);
  const schedEndDate =useSelector(state => state.scheduler.enddateandtime);
  const selectedStartDate = moment(schedStartDate.format());
  const selectedEndDate = moment(schedEndDate.format()).utcOffset(0);

 

  useEffect(() => {
    const timeinterval = setInterval(() => setTime(Date.now()), 100);
    return () => {
      clearInterval(timeinterval);
    };
  }, []);


    return (
 
  //const bull = <span className={classes.bullet}>•</span>;
  <React.Fragment> 
  <Hidden smDown>
    <Grid item xs={size}>
            <Paper elevation={6} className={classes.paper}>
              
                
            <Box display="flex" alignContent="stretch" alignItems="center" flexWrap="wrap" justifyContent="space-between">

 

              <Grid item xs={3}>
                <Typography key={time}variant="button" component="h2">
                {moment(selectedStartDate).tz(schedulerzone).format('YYYY/MM/DD')}<b>[{isTwelve ? moment(selectedStartDate).tz(schedulerzone).format('hh:mm A'):moment(selectedStartDate).tz(schedulerzone).format('HH:mm')}]</b>
                
                </Typography>
                </Grid>
              <Grid item xs={5}>
                <Typography variant="caption" component="h2">
                {isTwelve ? moment().tz(schedulerzone).format('hh:mm:ss A   '):moment().tz(schedulerzone).format('HH:mm:ss   ')}
                  [{schedulerzone}]
                </Typography>
                </Grid>
              <Grid item xs={3}>
                <Typography variant="button" component="h2">
    
              
               {moment(selectedEndDate).tz(schedulerzone).format('YYYY/MM/DD')}<b>[{isTwelve ? moment(selectedEndDate).tz(schedulerzone).format('hh:mm A'):moment(selectedEndDate).tz(schedulerzone).format('HH:mm')}]</b> 
                </Typography>
                </Grid>
                <Grid item xs={1}>
            
            <Typography className={classes.title} color="textSecondary" gutterBottom>

                </Typography>
                <Box display="flex" alignContent="flex-start" alignItems="flex-start" justifyContent="flex-end">
                <ChangeRemove schedulerkey={schedulerkey}/>
        </Box>
        </Grid>
              </Box>
              
              
            </Paper> 
          </Grid>
    </Hidden>
    <Hidden mdUp>
    <Grid item xs={size} container direction="column">
            <Paper elevation={6} className={classes.paper}>

                <Grid item xs={12}>
            <Box display="flex" alignContent="stretch" alignItems="center" flexWrap="wrap" justifyContent="space-between">
            <Grid item xs={12}>
            
            <Typography className={classes.title} color="textSecondary" gutterBottom>

                </Typography>
                <Box display="flex" alignContent="flex-start" alignItems="flex-start" justifyContent="flex-end">
                <ChangeRemove schedulerkey={schedulerkey}/>
        </Box>
        </Grid>

            <Grid item xs={12}>
                <Typography variant="caption" component="h2">
                {isTwelve ? moment().tz(schedulerzone).format('hh:mm:ss A   '):moment().tz(schedulerzone).format('HH:mm:ss   ')}
                  {(typeof schedulerzone)}[{schedulerzone}]
                  <p></p>
                </Typography>
                </Grid>

                
              

              <Grid item xs={12}>
                <Typography key={time}variant="button" component="h2">
                Start:{moment(selectedStartDate).tz(schedulerzone).format('YYYY/MM/DD')}<b>[{isTwelve ? moment(selectedStartDate).tz(schedulerzone).format('hh:mm A'):moment(selectedStartDate).tz(schedulerzone).format('HH:mm')}]</b>
                <p></p>
                <p></p>
                <p></p>
                </Typography>
                </Grid>
               
                <Grid item xs={12}>
                <Typography variant="button" component="h2">
    
              
               End  :{moment(selectedEndDate).tz(schedulerzone).format('YYYY/MM/DD')}<b>[{isTwelve ? moment(selectedEndDate).tz(schedulerzone).format('hh:mm A'):moment(selectedEndDate).tz(schedulerzone).format('HH:mm')}]</b> 
                </Typography>
                </Grid>
              

              </Box>
              </Grid>
              
            </Paper> 
          </Grid>
    </Hidden>
    </React.Fragment> 
  

   
    )
}
