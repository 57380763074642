import {combineReducers} from 'redux';
import isDarkReducer from './isDarkReducer';
import is12Reducer from './is12Reducer';
import counterReducer from './counterReducer';
import stopwatchReducer from './stopwatchReducer';
import timerReducer from './timerReducer';
import alarmReducer from './alarmReducer';
import clockReducer from './clockReducer';
import schedulerReducer from './schedulerReducer';


const allReducers =  combineReducers({
    isDark:isDarkReducer,
    isTwelve:is12Reducer,
    counter: counterReducer,
    stopwatch: stopwatchReducer,
    timer: timerReducer,
    alarm: alarmReducer,
    clock:clockReducer,
    scheduler:schedulerReducer
});

export default allReducers;