import React,{Component} from 'react';
//import { makeStyles } from '@material-ui/core/styles';
//import Context from "./context";
//import Switch from '@material-ui/core/Switch';
import {connect} from 'react-redux';
//import {toggleDark} from '../actions/isDarkActions';
//import { TOGGLE_DARK_MODE } from "../actions/types";
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
//import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
//import Button from '@material-ui/core/Button';
import SlideshowIcon from '@material-ui/icons/Slideshow';
import PausePresentationIcon from '@material-ui/icons/PausePresentation';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
//import {zonedTimeToUtc, utcToZonedTime, format} from 'date-fns-tz';
//import { eoLocale} from 'date-fns/locale/eo';
//import enGB from 'date-fns/locale/en-GB';
//import { format } from 'moment';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import  moment from 'moment-timezone';
//import timeZone from 'moment-timezone';
import {startStopwatch, stopStopwatch, resetStopwatch, tickStopwatch, getStopwatch} from '../actions/stopwatchActions';


class StopwatchComponent extends Component  {

  componentDidMount() {
    
  }
  componentWillUnmount(){
    
  }
render() {
  
  const time = this.props.time;
  
  
return ( 
  
  
  <Box display="flex" alignContent="stretch" flexWrap="wrap" alignSelf="stretch" justifyContent="space-between">
  <Paper elevation={2}> 

      <Typography key={time}variant="h3" component="h2" color="textSecondary">
        {moment().hour(0).minute(0).second(0).milliseconds(this.props.time).format('HH:mm:ss:SS')}

        <Tooltip title="Copy time"><CopyToClipboard text={moment().hour(0).minute(0).second(0).milliseconds(this.props.time).format('HH : mm : ss : SS')}>
        <IconButton color="inherit" aria-label="Copy time to clipboard" component="span" size="small">
        <FileCopyOutlinedIcon/>
        </IconButton>
        </CopyToClipboard>
        </Tooltip>
        
      </Typography>
      </Paper>
      <Typography variant="h5" component="h2" color="textSecondary">
        <b>STOPWATCH</b>
      </Typography>
  <Typography color="textSecondary" gutterBottom>

      </Typography>

      <Grid >
      <Tooltip title="Start" placement="bottom">
<IconButton variant="contained" onClick={() => this.props.startStopwatch()}>
<SlideshowIcon />
</IconButton>
</Tooltip>
<Tooltip title="Pause" placement="bottom">
<IconButton variant="contained" onClick={() => this.props.stopStopwatch()}>
<PausePresentationIcon />
</IconButton>
</Tooltip>
<Tooltip title="Reset" placement="bottom">
<IconButton variant="contained" onClick={() => this.props.resetStopwatch()}>
<CancelPresentationIcon />
</IconButton>
</Tooltip>       
</Grid>
     
    </Box>
  

  )
}
}
const mapStateToProps = state => ({
  time: state.stopwatch.time,
  running: state.stopwatch.running
});

export default connect(mapStateToProps, {startStopwatch, stopStopwatch, resetStopwatch, tickStopwatch, getStopwatch})(StopwatchComponent);
