import React,{ Component} from 'react';
//import { makeStyles } from '@material-ui/core/styles';
//import Context from "./context";
//import Switch from '@material-ui/core/Switch';
import {connect} from 'react-redux';
//import {toggleDark} from '../actions/isDarkActions';
//import { TOGGLE_DARK_MODE } from "../actions/types";
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import AlarmIcon from "@material-ui/icons/AddAlarm";
import InputAdornment from "@material-ui/core/InputAdornment";
import Tooltip from '@material-ui/core/Tooltip';
//import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
//import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
//import Button from '@material-ui/core/Button';
//import SlideshowIcon from '@material-ui/icons/Slideshow';
//import PausePresentationIcon from '@material-ui/icons/PausePresentation';
//import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import StopIcon from '@material-ui/icons/Stop';
import { MuiPickersUtilsProvider, TimePicker} from '@material-ui/pickers';
import MomentUtils from "@date-io/moment";
//import {zonedTimeToUtc, utcToZonedTime, format} from 'date-fns-tz';
//import { eoLocale} from 'date-fns/locale/eo';
//import enGB from 'date-fns/locale/en-GB';
//import { format } from 'moment';
//import {CopyToClipboard} from 'react-copy-to-clipboard';
import  moment from 'moment-timezone';
//import timeZone from 'moment-timezone';
import {startTimer, stopTimer, setTimer, tickTimer, resetTimer} from '../actions/timerActions';



class TimerComponent extends Component  {

  componentDidMount() {
    
  }
  componentWillUnmount(){
    
  }
  
render() {
  
  const time = this.props.time;
  const timer = this.props.timer;
  //const stamp = this.props.stamp;
  //const currentzone = moment.tz.guess();
  //const utcTime = zonedTimeToUtc(time, currentzone);
  //const zonedTime = utcToZonedTime(time, currentzone);
  var m = moment().utcOffset(0);
  m.set({hour:0,minute:0,seconds:0});
  m.toISOString();
  //const momenttime = moment(time);

  
return ( 
  
  
    <Box display="flex" alignContent="stretch" flexWrap="wrap" alignSelf="stretch" justifyContent="space-between">
    <Paper elevation={2} > 
    
        <Typography variant="h2" component="h2" color="textSecondary">
          {moment(timer).format('HH:mm:ss')}

        </Typography>
        
        </Paper>
       
        <Typography variant="h5" component="h1" color="textSecondary">
          <b>TIMER</b>
          <br></br>
                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <TimePicker
                  variant="dialog"
                  ampm={false}
                  autoOk={true}
                  showTodayButton={false}
                  value={time}
                  label="Set timer"
                  format="HH:mm:ss"
                  openTo="hours"
                  onChange={timerset => this.props.setTimer(timerset)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton>
                          <AlarmIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}

                />
              </MuiPickersUtilsProvider>                        

          </Typography>

        

<Grid >

<Tooltip title="Start" placement="bottom">
<IconButton variant="contained" onClick={() => this.props.startTimer()}>
<PlayArrowIcon/>
</IconButton>
</Tooltip>
<Tooltip title="Pause" placement="bottom">
<IconButton variant="contained" onClick={() => this.props.stopTimer()}>
<PauseIcon/>
</IconButton>
</Tooltip>
<Tooltip title="Stop/Reset" placement="bottom">
<IconButton variant="contained" onClick={() => this.props.resetTimer()}>
<StopIcon/>
</IconButton>
</Tooltip>   
   
</Grid>
      </Box>
  

  )
}
}
const mapStateToProps = state => ({
  time: state.timer.time,
  running: state.timer.running,
  stamp: state.timer.stamp,
  timer: state.timer.timer
});


const mapDispatchToProps = {
    startTimer,
     stopTimer,
      setTimer,
       tickTimer,
        resetTimer
  };

export default connect(mapStateToProps, mapDispatchToProps)(TimerComponent);
