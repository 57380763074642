import React, {useEffect} from 'react';
import { Route } from 'react-router-dom';
//import LinksPage from './LinksPage';
import AutoGrid from './AutoGrid';
import cityroutes from './Cityandzoneroutes.json';
import allcities from '../data/allcities.json';
//import { setClockMainZone} from '../actions/clockActions';
//import { setSchedulerMainZone} from '../actions/schedulerActions';
import mainstore from '../store';
//import {Helmet} from "react-helmet";
import  moment from 'moment-timezone';
import Alert from '@material-ui/lab/Alert';
import { AlertTitle } from '@material-ui/lab';
//import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
//import Typography from '@material-ui/core/Typography';
//import Slide from '@material-ui/core/Slide';
import Schedulerpage from './Schedulerpage';
import { setSchedulerEnd, setSchedulerStart, setSchedulerZone, resetScheduler } from '../actions/schedulerActions';
import { setClockZone, resetClock } from '../actions/clockActions';
import { toggleDark } from '../actions/isDarkActions';
import { toggleTwelve } from '../actions/is12Actions';
//var base64 = require('base-64');


let alltimezones =[];

var count = allcities.length;

for(var i = 0; i < count; i++){
  alltimezones[i] = allcities[i];
}


let itemsbyname= [];
let timezonebyname= [];
alltimezones.map(item =>
  itemsbyname[item.number]= {city: (item.asciiname+","+item.country), number :item.number}
);
alltimezones.map(item =>
  timezonebyname[item.number]= item.timezone
);



export default function ShareRoutes(props) {
  
  function tobase64(number) {
    let rep = {'0':0,'1':1,'2':2,'3':3,'4':4,'5':5,'6':6,'7':7,'8':8,'9':9,'a':10,'b':11,'c':12,'d':13,'e':14,'f':15,'g':16,'h':17,'i':18,'j':19,'k':20,'l':21,'m':22,'n':23,'o':24,'p':25,'q':26,'r':27,'s':28,'t':29,'u':30,'v':31,'w':32,'x':33,'y':34,'z':35,'A':36,'B':37,'C':38,'D':39,'E':40,'F':41,'G':42,'H':43,'I':44,'J':45,'K':46,'L':47,'M':48,'N':49,'O':50,'P':51,'Q':52,'R':53,'S':54,'T':55,'U':56,'V':57,'W':58,'X':59,'Y':60,'Z':61,'-':62,'=':63};
    var num = number.split("").reverse();
    var result = 0;
    num.map((array) => {result = result*64 + rep[array];});
    // console.log(result);
    return result;
    }
  
function Invalid() {
  const [open, setOpen] = React.useState(true);
return(
  <React.Fragment>
  <Collapse in={open}>
    <Alert severity="success" color="error" onClose={() => {setOpen(false);}}>
       <AlertTitle>404:Link "https://dateandtime.in/{location}" not found !</AlertTitle>
       You have been redirected to the Home Page</Alert>
  </Collapse>
  <Route path="/" component={AutoGrid} status={404}/>
    </React.Fragment>
)
}

  const location = props.location.pathname.replace("/share/","");
    //console.log(location);
  var base3 = "";
  var newInputZone = 0;
  var newSize = 0;
  const isClock= location.slice(0,1);
  const isDark= location.slice(2,3);
  const isTwelve= location.slice(1,2);
  useEffect(()=>{
  if (isClock == 'S') {
  const startdate= location.slice(3,9);
  const enddate= location.slice(9,15);
  var zoneslist = location.substr(15);
  // if (isDark=="D") { mainstore.dispatch(toggleDark());}
  // if (isTwelve=="A") { mainstore.dispatch(toggleTwelve());}
  mainstore.dispatch(resetScheduler());
  while (zoneslist){
    base3 = zoneslist.slice(0,3);
    zoneslist = zoneslist.substr(3);
    // console.log(base3);
    
    newInputZone= tobase64(base3);
    
    if (timezonebyname[newInputZone]){
    mainstore.dispatch(setSchedulerZone({timezone:timezonebyname[newInputZone], item:itemsbyname[newInputZone]}));
    }// console.log(timezonebyname[newInputZone]);
  };
  // console.log(isClock,isDark,isTwelve,zoneslist);
  // console.log(startdate,enddate,moment.unix(startdate));
  mainstore.dispatch(setSchedulerStart(moment.unix(tobase64(startdate))));
  mainstore.dispatch(setSchedulerEnd(moment.unix(tobase64(enddate))));
  } else  if (isClock == 'C') {

    var zoneslist = location.substr(3);
    // if (isDark=="D") { mainstore.dispatch(toggleDark());}
    // if (isTwelve=="A") { mainstore.dispatch(toggleTwelve());}
    mainstore.dispatch(resetClock());
    while (zoneslist){
      base3 = zoneslist.slice(0,3);
      newSize = zoneslist.slice(3,4);
      zoneslist = zoneslist.substr(4);
      // console.log(base3);
      
      newInputZone= tobase64(base3.slice(0,3));
      
      if (timezonebyname[newInputZone]){
      // console.log(newSize, base3,base3.slice(0,3),base3.substr(3))
      mainstore.dispatch(setClockZone({size:(newSize ==8) ? 12:parseInt(newSize),timezone:timezonebyname[newInputZone], item:itemsbyname[newInputZone]}));
      // console.log(timezonebyname[newInputZone]);
      }
    };
    // console.log(isClock,isDark,isTwelve,zoneslist);
    // console.log(startdate,enddate,moment.unix(startdate));
    // mainstore.dispatch(setSchedulerStart(moment.unix(tobase64(startdate))));
    // mainstore.dispatch(setSchedulerEnd(moment.unix(tobase64(enddate))));
  };
   if (isDark=="D") { mainstore.dispatch(toggleDark());}
   if (isTwelve=="A") { mainstore.dispatch(toggleTwelve());}
  }, [])
  let link="";

  

  const city = (cityroutes.find(item => item.Link.toLowerCase() == location.toLowerCase()))||null;
 
  const checked =true;
  //console.log("shared route");
    return (
      
        <div>
            {/* <Typography>Redirecting...</Typography> */}
            {/* <React.Fragment> */}
            {/* <Router> */}
               {(isClock === 'C')? <Route component={AutoGrid}/>: null}
                {(isClock === 'S')? <Route component={Schedulerpage}/>: null}
                {((isClock !== 'C') && (isClock !== 'S'))?  <Invalid/> : null}
                {/* <Route path="/zones" exact component={LinksPage}/> */}
            {/* </Router> */}
                {/* { (isDark=="D") ? mainstore.dispatch(toggleDark()) : null}
                { (isTwelve=="A") ? mainstore.dispatch(toggleTwelve()) : null} */}
            {/* { city ? mainstore.dispatch(setClockMainZone(city.TimeZone)) : null}
            { city ? mainstore.dispatch(setSchedulerMainZone(city.TimeZone)) : null}
            { city ? moment.tz.setDefault(city.TimeZone) : null} */}
            
            {/* </React.Fragment>  */}
            
        </div> 
      
    )
  }
