import {START_TIMER, TICK_TIMER, STOP_TIMER, RESET_TIMER, SET_TIMER, ALERT_TIMER} from './types';
import  moment from 'moment-timezone';

var m = moment().utcOffset(0);
m.set({hour:0,minute:0,seconds:0});
m.toISOString();
var t = moment().utcOffset(0);
t.set({hour:0,minute:0,seconds:0});
t.toISOString();

let timer = null;

export const startTimer = () => dispatch => {
    clearInterval(timer);
    timer = setInterval(() => dispatch(tickTimer()), 1000);
    dispatch({type: START_TIMER});
    
   }

export const stopTimer = () => dispatch => {
    clearInterval(timer);
    dispatch({
     type: STOP_TIMER
    })
   }


export const resetTimer = () => dispatch => {
    clearInterval(timer);
    dispatch({
     type: RESET_TIMER
    })
   }

export const tickTimer = () => (dispatch, getState) => {
    if(getState().timer.timer <= (moment(t.format()).utcOffset(0))){
        clearInterval(timer);
        dispatch({
            type:ALERT_TIMER 
           }) 
    } else {
    dispatch({
     type:TICK_TIMER 
    })
    }
   }


export const setTimer = (timerset) => dispatch => {
    dispatch({
     type:SET_TIMER,
     payload:timerset
    })
   }


export const alertTimer = () => (dispatch,getState) => {
    clearInterval(timer);
     dispatch({
     type:ALERT_TIMER,
    })
   }