import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
//import Card from '@material-ui/core/Card';
//import CardActions from '@material-ui/core/CardActions';
//import CardContent from '@material-ui/core/CardContent';
//import Button from '@material-ui/core/Button';
//import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
//import Box from '@material-ui/core/Box';
//import TextField from '@material-ui/core/TextField';
//import IconButton from '@material-ui/core/IconButton';
//import Button from '@material-ui/core/Button';
//import Tooltip from '@material-ui/core/Tooltip';
//import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
//import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
//import PlayArrowIcon from '@material-ui/icons/PlayArrow';
//import PauseIcon from '@material-ui/icons/Pause';
//import StopIcon from '@material-ui/icons/Stop';
//import Paper from '@material-ui/core/Paper';
//import Grid from '@material-ui/core/Grid';
//import { format } from 'moment';
//import {CopyToClipboard} from 'react-copy-to-clipboard';
//import moment from 'moment';
//import timeZone from 'moment-timezone';
//import { MuiPickersUtilsProvider,  KeyboardDatePicker,  KeyboardTimePicker,  DateTimePicker} from '@material-ui/pickers';
//import MomentUtils from "@date-io/moment";
import TimerComponent from './TimerComponent';
//import {zonedTimeToUtc, utcToZonedTime, format} from 'date-fns-tz';
//import { eoLocale} from 'date-fns/locale/eo';
//import enGB from 'date-fns/locale/en-GB';




export default function Timer(props) {
  //const classes = useStyles();
  const size = props.size;
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    grids:
    {
      height: "100%",
      padding: theme.spacing(2),
     },
  }));


const classes = useStyles();
  //const [time, setTime] = useState(Date.now());
  //const currentzone = moment.tz.guess();
  //const utcTime = zonedTimeToUtc(time, currentzone);
  //const zonedTime = utcToZonedTime(time, currentzone);
  //var m = moment().utcOffset(0);
  //m.set({hour:0,minute:0,seconds:0});
  //m.toISOString();
  
  //const momenttime = moment(time);
  //const [selectedDate, setSelectedDate] = useState(moment(m.format()).utcOffset(0));

  //const handleDateChange = (date) => { setSelectedDate(date);  };

  //useEffect(() => {    const timeinterval = setInterval(() => setTime(Date.now()), 100); return () => { clearInterval(timeinterval); };  }, []);
 
  return (
 
  //const bull = <span className={classes.bullet}>•</span>;

<Grid item xs={size} className={classes.grids}>
<Paper elevation={6}  className={classes.paper}>
            <TimerComponent/>
            </Paper> 
          </Grid>
    )
}
