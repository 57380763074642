import React from 'react';
//import { BrowserRouter as Router, Route} from 'react-router-dom';
//import logo from './logo.svg';
//import './App.css';
//import Container from '@material-ui/core/Container';
import Scheduler from './Scheduler';
import  moment from 'moment-timezone';
//import DateTimePicker from './DateTimePicker';
//import Dashboard from './Components/Dashboard'
//import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
//import Modal from '@material-ui/core/Modal';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import Hidden from '@material-ui/core/Hidden';
// import Card from '@material-ui/core/Card';
// import CardActions from '@material-ui/core/CardActions';
// import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
//import AlarmIcon from "@material-ui/icons/AddAlarm";
import EventIcon from '@material-ui/icons/Event';
import InputAdornment from "@material-ui/core/InputAdornment";
//import Paper from '@material-ui/core/Paper';
import Fab from '@material-ui/core/Fab';
//import Link from '@material-ui/core/Link';
//import {Link} from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import CopyIconSvg from '../SvgIcons/CopyIconSvg';
import ShareIconSvg from '../SvgIcons/ShareIconSvg';
import EditIconSvg from '../SvgIcons/EditIconSvg';
import Tooltip from '@material-ui/core/Tooltip';
import {CopyToClipboard} from 'react-copy-to-clipboard';
//import MenuItem from '@material-ui/core/MenuItem';
// import FormHelperText from '@material-ui/core/FormHelperText';
// import FormControl from '@material-ui/core/FormControl';
// import Select from '@material-ui/core/Select';
// import TextField from '@material-ui/core/TextField';
// import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import mainstore from '../store';
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, DateTimePicker} from '@material-ui/pickers';
import Divider from '@material-ui/core/Divider';
import SchedulerzoneComponent from './SchedulerzoneComponent';
import { setSchedulerEnd, setSchedulerStart } from '../actions/schedulerActions';
import {useSelector} from 'react-redux';
import AdsInline from './AdsInline';
//import AdSense from 'react-adsense';
import ReactGA from "react-ga";
import {Helmet} from "react-helmet";

import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';

//const SchedulerzoneComponent  = React.lazy(() => import('./SchedulerzoneComponent'));
//var base64 = require('base-64');



const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    share: {
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    fab: {
      position: 'sticky',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
     
    },
  }));

function Schedulerlist(props) {
  return(
    props.schdulerarray.map((array,index) =>
      
    <Scheduler  key={index+"scheduler"} size={12}  schedulerzone={array.timezone} schedulerkey={index} scheduleritem={array.item}/>
    )
  )
  
}

function Pageview(props) {
  if ((typeof window !== 'undefined')&&(process.env.NODE_ENV == 'production')) {
    ReactGA.pageview(window.location.pathname + window.location.search);
    }
return null;
}

function tobase64(number) {
let rep = ['0','1','2','3','4','5','6','7','8','9','a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z','A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z','-','='];
var num = number;
let rem = 0;
let base =[];
while (num > 0) {
rem = num % 64;
base.push(rem);
num = Math.floor(num /64);
}
let textchar = [0,0,0,0,0,0];
base.map((array,index) => {textchar[index]=(rep[array]);});
let text = textchar.join("");
return text;
}

function zonebase64(number) {
  let rep = ['0','1','2','3','4','5','6','7','8','9','a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z','A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z','-','='];
  var num = number;
  let rem = 0;
  let base =[];
  while (num > 0) {
  rem = num % 64;
  base.push(rem);
  num = Math.floor(num /64);
  }
  let textchar = [0,0,0];
  base.map((array,index) => {textchar[index]=(rep[array]);});
  let text = textchar.join("");
  return text;
  }
  


export default function Schedulerpage() {
    const classes = useStyles();
    //const currentzone = moment.tz.guess();
    //const bull = <span className={classes.bullet}>•</span>;
    let countries = [];
    let timezones = [];
    moment.tz.names().map(timezone => 
      timezones.push(timezone));
    //const [age, setAge] = React.useState('');
    moment.tz.countries().map(timezone => 
      countries.push(timezone));

      // const [country, setCountry] = React.useState(null);
      // const [inputCountry, setInputCountry] = React.useState('');
      // const [zone, setZone] = React.useState(null);
      // const [inputZone, setInputZone] = React.useState('');
  
  const schedulerarray = useSelector(state => state.scheduler.schedulerarray);
  const isTwelve =  useSelector(state => state.isTwelve);
  const mainzone =  useSelector(state => state.scheduler.maintimezone);
  const isDark =  useSelector(state => state.isDark);
  moment.tz.setDefault(mainzone);
  const [accord, setAccord] = React.useState(true);
  const handleClick = (event) => {
    
     (accord) ? setAccord(false):setAccord(true);
  };
  

  

    // const handleChange = (event) => {
    //   setAge(event.target.value);
    // };

    const selectedStartDate =useSelector(state => state.scheduler.startdateandtime);
    

    const selectedEndDate =useSelector(state => state.scheduler.enddateandtime);
  
    const [copied, setCopied] = React.useState(false);
    
    const handleCopied = () => {
      setCopied(true);
      
      setTimeout(() => setCopied(false), 2000);
    };

    const [clipped, setClipped] = React.useState(false);
    
    const handleClipped = () => {
      setClipped(true);
      
      setTimeout(() => setClipped(false), 2000);
    };

    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };


    let schedulertext =[];
    schedulertext.push(isTwelve ?" Start date & time   -  End date & time     [Timezone]\n":"Start date & time - End date & time   [Timezone]\n")
    schedulerarray.map((array) => 
    schedulertext.push(
    moment(selectedStartDate).tz(array.timezone).format('YYYY/MM/DD')+"  "+
    (isTwelve ? moment(selectedStartDate).tz(array.timezone).format('hh:mm A'):moment(selectedStartDate).tz(array.timezone).format('HH:mm') )+
    " - "+
    moment(selectedEndDate).tz(array.timezone).format('YYYY/MM/DD')+"  "+
    (isTwelve ? moment(selectedEndDate).tz(array.timezone).format('hh:mm A'):moment(selectedEndDate).tz(array.timezone).format('HH:mm') )+
    " ["+array.timezone+"]  \n"
    ));
    
    schedulertext.push("\n Scheduled with https://dateandtime.in/scheduler")
  


    var schedulersharetext = "S";
    schedulersharetext =schedulersharetext.concat(isTwelve ? 'A': 'M');
    schedulersharetext =schedulersharetext.concat(isDark ? 'D': 'L');
    schedulersharetext =schedulersharetext.concat(tobase64(moment(selectedStartDate).unix()));
    // console.log(typeof(btoa(moment(selectedStartDate).unix())));
    schedulersharetext = schedulersharetext.concat(tobase64(moment(selectedEndDate).unix()));
    schedulerarray.map((array,index) =>
    (index ==0) ?null:schedulersharetext = schedulersharetext.concat(zonebase64(array.item.number))
    );
    
    return (
    
    <div>
      <Pageview key='scheduler'/>
      <Helmet>
    <title>Date & Time .in</title>
    <link rel="canonical" href={`https://dateandtime.in/scheduler`}></link>
    <meta name="description" content="Date and Time in different timezones. Schedule event/ meeting between different timzones. Timezone, City, UTC, Time, Date, Month."/>
    </Helmet>
        <Button className="App-link" color='secondary'  fullWidth variant='contained' target="_blank" rel="noopener noreferrer">
        <Typography variant='h4'>
        Meeting/Event Scheduler
        </Typography>
        </Button>
        <Divider/>
        &nbsp;
        
      <Grid item xs={12} container spacing={0}>
      
</Grid>
<Grid item xs={12} container spacing={0}>

<Grid item xs={12} md={4}>
 
<MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} key={mainzone+"start"}>
                <DateTimePicker
                  
                  clearable
                  ampm={isTwelve}
                  autoOk={true}
                  showTodayButton={true}
                  value={moment(selectedStartDate).tz(mainzone)}
                  label="Start time"
                  format={isTwelve ? "L hh:mm A":"L HH:mm"}
                  openTo="date"
                  onChange={startdate => mainstore.dispatch(setSchedulerStart(moment(startdate).tz(mainzone)))}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton>
                          <EventIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}

                />
              </MuiPickersUtilsProvider>  
              
</Grid>

              <Grid item  xs={12} md={4}>
                
              <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} key={mainzone+"end"}>
                <DateTimePicker
                   key={mainzone+"end"}
                  clearable
                  ampm={isTwelve}
                  autoOk={true}
                  showTodayButton={true}
                  value={moment(selectedEndDate).tz(mainzone)}
                  label="End time"
                  format={isTwelve ? "L hh:mm A":"L HH:mm"}
                  openTo="date"
                  onChange={enddate => mainstore.dispatch(setSchedulerEnd(moment(enddate).tz(mainzone)))}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton>
                          <EventIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </MuiPickersUtilsProvider>  
              <Tooltip title={(clipped)? "Copied to Clipboard":"Copy all dates and time"}>
              <CopyToClipboard text={schedulertext.join("")} className={classes.title} onCopy={() => handleClipped() }>
        <IconButton className={classes.clipbutton} color="inherit" aria-label="Copy to clipboard" component="span" size="small">
        <CopyIconSvg style={{ fontSize: 15}}/>
        </IconButton>
        </CopyToClipboard>
        </Tooltip>
        <Tooltip title={ (copied)? "Link Copied":"Share link for this schedule"}>
              <CopyToClipboard text={"https://dateandtime.in/share/"+schedulersharetext} className={classes.title} onCopy={() => handleCopied(true) }>
        <IconButton className={classes.clipbutton} color="inherit" aria-label="Copy to clipboard" component="span" size="small">
        <ShareIconSvg style={{ fontSize: 15}}/>
        </IconButton>
        </CopyToClipboard>
        </Tooltip>
              
</Grid>

</Grid>
<Grid item xs={12} container spacing={0}>
        {/* <Scheduler size={9} schedulerzone="Asia/Calcutta" schedulerkey={-1} />
        <Scheduler size={9} schedulerzone={zone || 'EST5EDT'} schedulerkey={-2} />
      <Scheduler size={9} schedulerzone="America/Argentina/Rio_Gallegos" schedulerkey={-3} />
      <Scheduler size={9} schedulerzone="Europe/London" schedulerkey={-4} />
      <Scheduler size={9} schedulerzone="Asia/Dubai" schedulerkey={-5} />
      <Scheduler size={9} schedulerzone="UTC" schedulerkey={-6} /> */}
      <Hidden smUp implementation="css">
      <Grid item xs={12}>
      <AdsInline page="scheduler" adskey="adsense"/>
      </Grid>
      </Hidden>
      <Grid item xs={12} sm={9} container spacing={0}>
      <Schedulerlist schdulerarray={schedulerarray}/>
      </Grid>
      <Hidden xsDown implementation="css">
      <Grid item xs={3}>
      <AdsInline page="scheduler" adskey="adsense"/>
      </Grid>
      </Hidden>
      </Grid>
        
        <p></p>
        <Fab color="secondary" aria-label="add" className={classes.fab} onClick={handleOpen}>
          <AddIcon />
        </Fab>
        <Dialog
  open={open}
  onClose={handleClose}
  aria-labelledby="simple-modal-title"
  aria-describedby="simple-modal-description"
>

<Box display="flex"  flexWrap="wrap"  justifyContent="center">
 

<React.Suspense fallback={<CircularProgress color="secondary" />}>
<SchedulerzoneComponent/>
</React.Suspense>


</Box>

</Dialog>
<MuiAccordion square expanded={accord} onClick={handleClick}> 
        <MuiAccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography color={(accord) ? "textSecondary":"textPrimary"}>{(accord) ? "How to Use:":"Help (?)"}</Typography>
        </MuiAccordionSummary>
        <MuiAccordionDetails>
          <Typography variant="caption" color="textSecondary">
            Use the Date and Time inputs to set the Start and End time for your meeting/event.<p></p>
            Click on the <b>+</b> button to add additional timezones.<p></p>
            Click on <b>X</b> to delete any additional schedules/tiles. <p></p>
            Click on <EditIconSvg style={{ fontSize: 10 }}/> to change the timezone of  the selected  tile.<p></p>
            Use the <ShareIconSvg style={{ fontSize: 10 }}/> icon above to copy the Share link, or use the <CopyIconSvg style={{ fontSize: 10 }}/> to copy the schedule in text format to clipboard.<p></p>
            Share the link with others so they can see the same schedule that you have setup.
            (Share link does not share the Main timezone, this is set automatically to their regional date and time.) <p></p>
            You can share the link without adding any additional timezones to share only the time. This will sync to the local timezone for anyone opening the link.
          </Typography>
        </MuiAccordionDetails>
      </MuiAccordion>
    </div>
  );
}
