import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
//import Card from '@material-ui/core/Card';
//import CardActions from '@material-ui/core/CardActions';
//import CardContent from '@material-ui/core/CardContent';
//import Button from '@material-ui/core/Button';
//import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
// import Box from '@material-ui/core/Box';
// import IconButton from '@material-ui/core/IconButton';
// import Tooltip from '@material-ui/core/Tooltip';
// import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
// import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
// import Button from '@material-ui/core/Button';
// import SlideshowIcon from '@material-ui/icons/Slideshow';
// import PausePresentationIcon from '@material-ui/icons/PausePresentation';
// import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
//import Paper from '@material-ui/core/Paper';
//import Grid from '@material-ui/core/Grid';
// import { format } from 'moment';
// import {CopyToClipboard} from 'react-copy-to-clipboard';
//import moment from 'moment';
//import timeZone from 'moment-timezone';
//import {zonedTimeToUtc, utcToZonedTime, format} from 'date-fns-tz';
//import { eoLocale} from 'date-fns/locale/eo';
//import enGB from 'date-fns/locale/en-GB';
// import {connect} from 'react-redux';
// import {startStopwatch, stopStopwatch, resetStopwatch, tickStopwatch} from '../actions/stopwatchActions';
import StopwatchComponent from './StopwatchComponent';







export default function Stopwatch(props) {

   const useStyles = makeStyles((theme) => ({
      root: {
        flexGrow: 1,
      },
      paper: {
        
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
      },
      time: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
      },
      small: {
        padding: theme.spacing(0),
        textAlign: 'center',
        color: theme.palette.text.secondary,
      },
      grids:
      {
        height: "100%",
        padding: theme.spacing(2),
       },
       clipbutton:
       {
         height: "100%",
         padding: theme.spacing(2),
         color: theme.palette.text.secondary,
        },
    }));

  
  const classes = useStyles();
  const size = props.size;
  //const [time, setTime] = useState(Date.now());
  //const currentzone = moment.tz.guess();
  //const utcTime = zonedTimeToUtc(time, currentzone);
  //const zonedTime = utcToZonedTime(time, currentzone);
  //const momenttime = moment(time);
  

  // useEffect(() => {
  //   const timeinterval = setInterval(() => setTime(Date.now()), 100);
  //   return () => {
  //     clearInterval(timeinterval);
  //   };
  // }, []);

  return (
 
  //const bull = <span className={classes.bullet}>•</span>;
<Grid item xs={size} className={classes.grids}>
<Paper elevation={6}  className={classes.paper}>
  
    <StopwatchComponent/>
    
  </Paper> 
  </Grid>
    )
  }