import React from 'react';
//import { BrowserRouter as Router, Route} from 'react-router-dom';
//import logo from './logo.svg';
//import './App.css';
//import Container from '@material-ui/core/Container';
//import DateTimePicker from './DateTimePicker';
//import Dashboard from './Components/Dashboard'
//import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
//import Card from '@material-ui/core/Card';
//import CardActions from '@material-ui/core/CardActions';
//import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
//import Fab from '@material-ui/core/Fab';
import Divider from '@material-ui/core/Divider';
//import Link from '@material-ui/core/Link';
//import AddIcon from '@material-ui/icons/Add';
//import  moment from 'moment-timezone';
import Timer from './Timer'
//import { makeStyles } from '@material-ui/core/styles';
import Stopwatch from './Stopwatch';
import Alarm from './Alarm';
import AdsInline from './AdsInline';
//import AdSense from 'react-adsense';
import ReactGA from "react-ga";
import {Helmet} from "react-helmet";

// const useStyles = makeStyles((theme) => ({
//   root: {
//     flexGrow: 1,
//   },
//   paper: {
//     padding: theme.spacing(2),
//     textAlign: 'center',
//     color: theme.palette.text.secondary,
//   },
//   fab: {
//     position: 'fixed',
//     bottom: theme.spacing(2),
//     right: theme.spacing(2),
//   },
// }));
function Pageview(props) {
  if ((typeof window !== 'undefined')&&(process.env.NODE_ENV == 'production')) {
    ReactGA.pageview(window.location.pathname + window.location.search);
    }
return null;
}


export default function Timerpage() {
    //const classes = useStyles();
    //const bull = <span className={classes.bullet}>•</span>;
    
    return (
    
    <div>
      <Pageview key='timer'/>
      <Helmet>
    <title>Date & Time .in</title>
    <link rel="canonical" href={`https://dateandtime.in/timer`}></link>
    <meta name="description" content="Date and Time in different timezones. Timer,Alarm,Stopwatch. Use Alarm and Timer for alerts; track time with Stopwatch."/>
    </Helmet>
      <Button className="App-link" color='secondary'  fullWidth variant='contained' target="_blank" rel="noopener noreferrer">
        <Typography variant='h4'>
        
        Timer,Stopwatch & Alarm
        
        </Typography>
        </Button>
        <Divider/>
        &nbsp;
      <Grid item xs={12} container spacing={1}>
      <Grid item sm={12} md={8} container spacing={1}>
      <Hidden mdUp>
      <Grid item xs={12} container spacing={0}>
      <AdsInline page="timer" adskey="adsense"/>
      </Grid>

      </Hidden>
      <Grid item sm={12} md={8} container spacing={1}>
        <Timer size={12}/>
      </Grid>
      <Grid item sm={12} md={8} container spacing={1}>
        <Stopwatch size={12}/>          
      </Grid>
      
        
      <Grid item sm={12} md={8} container spacing={1}>
        <Alarm size={12}/>          
      </Grid>
      </Grid>
      <Hidden smDown>
      <Grid item xs={4} container spacing={0}>
      <AdsInline page="timer" adskey="adsense"/>
      </Grid>

      </Hidden>
      </Grid>
      

    </div>
  );
}

