export const INCREMENT = 'INCREMENT';
export const DECREMENT = 'DECREMENT';
export const TOGGLE_DARK_MODE = 'TOGGLE_DARK_MODE';
export const TOGGLE_TWELVE_MODE = 'TOGGLE_TWELVE_MODE';
export const CHANGE_CLOCK_ZONE = 'CHANGE_CLOCK_ZONE';
export const CHANGE_CLOCK_SIZE = 'CHANGE_CLOCK_SIZE';
export const ADD_CLOCK = 'ADD_CLOCK';
export const SET_CLOCK_ZONE = 'SET_CLOCK_ZONE';
export const DELETE_CLOCK = 'DELETE_CLOCK';
export const SET_CLOCK_MAIN_ZONE = 'SET_CLOCK_MAIN_ZONE'
export const RESET_CLOCK = 'RESET_CLOCK';
export const SET_CLOCK_SIZE = 'SET_CLOCK_SIZE';
export const CHANGE_SCHEDULER_ZONE = 'CHANGE_SCHEDULER_ZONE';
export const ADD_SCHEDULER = 'ADD_SCHEDULER';
export const SET_SCHEDULER_ZONE = 'SET_SCHEDULER_ZONE';
export const DELETE_SCHEDULER = 'DELETE_SCHEDULER';
export const SET_SCHEDULER_MAIN_ZONE = 'SET_SCHEDULER_MAIN_ZONE';
export const RESET_SCHEDULER = 'RESET_SCHEDULER';
export const SET_SCHEDULER_START = 'SET_SCHEDULER_START';
export const SET_SCHEDULER_END = 'SET_SCHEDULER_END';
export const START_STOPWATCH = 'START_STOPWATCH';
export const STOP_STOPWATCH = 'STOP_STOPWATCH';
export const RESET_STOPWATCH = 'RESET_STOPWATCH';
export const TICK_STOPWATCH = 'TICK_STOPWATCH';
export const GET_STOPWATCH = 'GETICK_STOPWATCH';
export const START_TIMER = 'START_TIMER';
export const SET_TIMER = 'SET_TIMER';
export const RESET_TIMER = 'RESET_TIMER';
export const STOP_TIMER = 'STOP_TIMER';
export const TICK_TIMER = 'TICK_TIMER';
export const ALERT_TIMER = 'ALERT_TIMER';
export const SET_ALARM = 'SET_ALARM';
export const START_ALARM = 'START_ALARM';
export const STOP_ALARM = 'STOP_ALARM';
export const TOGGLE_ALARM = 'TOGGLE_ALARM';
export const RESET_ALARM = 'RESET_ALARM';
export const ALERT_ALARM = 'ALERT_ALARM';
export const TICK_ALARM = 'TICK_ALARM';
export const CHANGE_CALENDAR_ZONE = 'CHANGE_CALENDAR_ZONE';
