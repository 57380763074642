import React,{ Component} from 'react';
//import { makeStyles } from '@material-ui/core/styles';
//import Context from "./context";
import Switch from '@material-ui/core/Switch';
import {connect} from 'react-redux';
//import {toggleDark} from '../actions/isDarkActions';
//import { TOGGLE_DARK_MODE } from "../actions/types";
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import AlarmIcon from "@material-ui/icons/AddAlarm";
import InputAdornment from "@material-ui/core/InputAdornment";
import Tooltip from '@material-ui/core/Tooltip';
//import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
//import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
//import Button from '@material-ui/core/Button';
//import SlideshowIcon from '@material-ui/icons/Slideshow';
//import PausePresentationIcon from '@material-ui/icons/PausePresentation';
//import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
//import PlayArrowIcon from '@material-ui/icons/PlayArrow';
//import PauseIcon from '@material-ui/icons/Pause';
import StopIcon from '@material-ui/icons/Stop';
import { MuiPickersUtilsProvider, TimePicker} from '@material-ui/pickers';
import MomentUtils from "@date-io/moment";
//import {zonedTimeToUtc, utcToZonedTime, format} from 'date-fns-tz';
//import { eoLocale} from 'date-fns/locale/eo';
//import enGB from 'date-fns/locale/en-GB';
//import { format } from 'moment';
//import {CopyToClipboard} from 'react-copy-to-clipboard';
import  moment from 'moment-timezone';
//import timeZone from 'moment-timezone';
import { toggleAlarm, setAlarm, alertAlarm, resetAlarm} from '../actions/alarmActions';


class AlarmComponent extends Component  {

  componentDidMount() {
    
  }
  componentWillUnmount(){
    
  }
  
render() {
  
  const time = this.props.time;
  const isAlarmON = this.props.running;
  const isTwelve = this.props.isTwelve;
  const mainzone = this.props.mainzone;
  //const stamp = this.props.stamp;
  //const currentzone = moment.tz.guess();
  //const utcTime = zonedTimeToUtc(time, currentzone);
  //const zonedTime = utcToZonedTime(time, currentzone);
  var m = moment().utcOffset(0);
  m.set({hour:0,minute:0,seconds:0});
  m.toISOString();
  //const momenttime = moment(time);

  
return ( 
  
  
    <Box display="flex" alignContent="stretch" flexWrap="wrap" alignSelf="stretch" justifyContent="space-between">
    <Paper elevation={2} > 

        <Typography key={time}variant="h2" component="h2" color="textSecondary">
        {isTwelve ? moment(time).format('hh:mm:00') : moment(time).format('HH:mm:00')}
        
        
        </Typography>
        {isTwelve ? <Typography variant="button" color="textSecondary"><b>{moment(time).format('A')}</b></Typography> : null}
        </Paper>
        
        
    
    

      &nbsp;
        
        <Typography variant="h5" component="h1" color="textSecondary">
          <b>ALARM</b>
          <br></br>
                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <TimePicker
                  
                  ampm={isTwelve}
                  autoOk={true}
                  showTodayButton={true}
                  value={moment(time).tz(mainzone)}
                  label="Set alarm"
                  format={isTwelve ? "hh:mm A":"HH:mm"}
                  openTo="hours"
                  onChange={alarmset => this.props.setAlarm(moment(alarmset).tz(mainzone))}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton>
                          <AlarmIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}

                />
              </MuiPickersUtilsProvider>                        
              
          </Typography>

        

<Grid >
<Typography variant="button" component="h1" color="textSecondary">
            OFF
          <Switch checked={isAlarmON} onChange={() => this.props.toggleAlarm()}/>
          ON
          &nbsp;&nbsp;&nbsp;&nbsp;
          <Tooltip title="Reset" placement="bottom">
<IconButton variant="contained" onClick={() => this.props.resetAlarm()}>
<StopIcon/>
</IconButton>
</Tooltip>  
</Typography>
 
   
</Grid>
      </Box>
  

  )
}
}
const mapStateToProps = state => ({
  time: state.alarm.time,
  running: state.alarm.running,
  stamp: state.alarm.stamp,
  alert: state.alarm.alert,
  isTwelve: state.isTwelve,
  mainzone: state.alarm.mainzone
});


const mapDispatchToProps = {
    toggleAlarm,     
      setAlarm,
      alertAlarm,
        resetAlarm
  };

export default connect(mapStateToProps, mapDispatchToProps)(AlarmComponent);
