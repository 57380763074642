import {START_TIMER, TICK_TIMER, STOP_TIMER, RESET_TIMER, SET_TIMER, ALERT_TIMER} from '../actions/types';
import moment from 'moment-timezone';


var m = moment().utcOffset(0);
m.set({hour:0,minute:0,seconds:0});
m.toISOString();

let initialState = {
  time: moment(m.format()).utcOffset(0),
  timer:moment(m.format()).utcOffset(0),
  running: false,
  stamp: 0,
  alert:false
}


const timerReducer = (state = initialState, action) => {
    switch (action.type) {
      case START_TIMER:
        return {
          ...state,
          timer:state.timer,
          running: true,
          stamp: moment()
        };
      case STOP_TIMER:
        return{
          ...state,
          running: false,
          alert:false
        };
    
      case RESET_TIMER:{
        return initialState;
      }
      case TICK_TIMER:
        return {
          ...state,
          timer: (moment(state.timer).subtract(1, 'seconds'))
        };
      case SET_TIMER:
          return {
            ...state,
            time: action.payload,
            timer:action.payload
          };
      case ALERT_TIMER:
            return{
              ...state,
              alert: true
        };
      
      default:
        return state;
    }
  }

export default timerReducer;