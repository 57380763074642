import {createStore, applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk';
import allReducers from './reducers';



const initialState ={};
const middleware = [thunk];
const devTools = ((process.env.NODE_ENV !== 'production') && (window.__REDUX_DEVTOOLS_EXTENSION__)) ? window.__REDUX_DEVTOOLS_EXTENSION__() : (a) => a;
const mainstore = createStore(allReducers,
     initialState,
     compose(applyMiddleware(...middleware),devTools)
     );

export default mainstore;

