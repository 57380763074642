import React, {Component} from "react";
//import Context from "./context";
import Switch from '@material-ui/core/Switch';
//import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {connect} from 'react-redux';
import {toggleDark} from '../actions/isDarkActions';
//import { TOGGLE_DARK_MODE } from "../actions/types";

class DarkLightSwitch extends Component  {

render() {
  const isDark = this.props.isDark;
return ( 
  <Typography component="label" color="textSecondary">
    
  <Grid container alignItems="center" spacing={0}>
    <Grid item>LIGHT</Grid>
    <Grid item>
      <Switch checked={isDark} onChange={() => this.props.toggleDark()}/>
      </Grid>
          <Grid item>DARK</Grid>
        </Grid>
      </Typography>
  )
}
}
const mapStateToProps = state => ({
  isDark: state.isDark
});
export default connect(mapStateToProps, {toggleDark})(DarkLightSwitch);